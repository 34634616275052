import * as logger from "@/tools/logger.js";

import * as statusMapper from "@/service/error_request_mapper.js";

const _FILE_PATH = "service/establishment/establishment_service.js";
const _M_GET_DIGITAL_NAME = "getDigitalNames";
const _M_GET_ALL = "getEstablishments";
const _M_GET_ETABLIS_BY_DIGITAL_NAME = "getEtablishmentByDigitalName";
const _M_GET_ETABLIS_BY_ID = "getEtablishmentById";
const _M_GET_RAW_BY_ID = "getRawById";
const _M_CREATE = "create";
const _M_UPDATE = "update";
const _M_UPDATE_RAW = "updateRaw";

export default class EstablishmentsService {
  constructor(apis) {
    this.apis = apis;

    this.api = this.apis.getEtablishmentApi();
  }

  async getAll() {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_ALL,
      "Récupération des établissements."
    );

    return this.api
      .getEstablishments()
      .then((data) => {
        data.sort(function(a, b) {
          return a.digitalName.localeCompare(b.digitalName);
        });

        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  async getEstablishments() {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_ALL,
      "Récupération des établissements."
    );

    return this.getAll();
  }

  async getById(establishmentId) {
    return this.api.getEtablishmentById(establishmentId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(establishmentId);
      converter.convert(error);
    });
  }

  /**retourne les identifiants des activités de l'établissement */
  async getActivityIds(establishmentId) {
    return this.api.getActivities(establishmentId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }
  
  /**
   * Retourne la liste des établissements pour une activité
  */
  async getEstablishmentOfActivity(activityId) {
    return this.api.getEstablishmentOfActivity(activityId)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** retourne les couples activité -- url pour l'établissement */
  async getUrlOFActivities(establishmentId) {
    return this.api.getUrlOFActivities(establishmentId)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /**retourne la partie raw de l'établissement */
  async getRawById(establishmentId) {
    return this.api
      .getRawById(establishmentId)
      .then((data) => data.raw)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(establishmentId);
        converter.convert(error);
      });
  }

  /**
   * récupérer la liste des noms digitaux des établissements
   */
  async getDigitalNames() {
    return this.api
      .getDigitalNames()
      .then((data) => {
        data.sort(function(a, b) {
          return a.digitalName.localeCompare(b.digitalName);
        });

        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /**
   * Ajouter un établissement
   */
  async create(establishment) {
    return this.api.create(establishment).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409AlreadyExists(establishment.digitalName);
      converter.convert(error);
    });
  }

  /** Création d'un tuple activité url pour un établissement */
  async createUrlActivity(establishmentId, website) {
    return this.api.createUrlActivity(establishmentId, website)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409AlreadyExists(website.website);
      converter.convert(error);
    });
  }


  /** Mise à jour  d'un tuple activité url pour un établissement */
  async updateUrlActivity(establishmentId, website) {
    return this.api.updateUrlActivity(establishmentId, website)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(website.website);
      converter.convert(error);
    });
  }

  //** Supprimer un établissement */
  async deleteEtablishment(etablishmentId) {
    return this.api.deleteEtablishment(etablishmentId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(etablishmentId);
      converter.add409Integrity(etablishmentId);
      converter.convert(error);
    });
  }

  //** Met à jour les informations d'un établissement */
  async update(etablishment) {
    return this.api.update(etablishment).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(etablishment.id);
      converter.convert(error);
    });
  }

  
  /** Met à jour les infos du raw d'un établissement */
  async updateRaw(etablishmentId, raw) {
    return this.api.updateRaw(etablishmentId, raw)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(etablishmentId);
      converter.convert(error);
    });
  }

  async establishmentsWithSubActivity(subActivityId) {
    return this.api
      .establishmentsWithSubActivity(subActivityId)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /** Récupération de l'ensemble des couples estab/fonction pour un estab */
  async getAllEstablishmentFunctionForEstablishment(establishmentId) {
    return this.api.getAllEstablishmentFunctionForEstablishment(establishmentId)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** Ajout d'un ensemble establishment/function pour un estab */
  async createSupportForEstablishment(establishmentId, datas) {
    return this.api.createSupportForEstablishment(establishmentId, datas)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409AlreadyExists(establishmentId);
      converter.convert(error);
    });
  }

  /** Mise à jour de l'ensemble establishment/fonction pour un estab */
  async updateSupportForEstablishment(establishmentId, functionId, datas) {
    return this.api.updateSupportForEstablishment(establishmentId, functionId, datas)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(establishmentId);
      converter.convert(error);
    });
  }

  /** Suppression de l'ensemble couple estab/function */
  async deleteSupportForEstablishment(establishmentId, functionId) {
    return this.api.deleteSupportForEstablishment(establishmentId, functionId)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(establishmentId);
      converter.add409Integrity(establishmentId);
      converter.convert(error);
    });
  }

  ////////////////////// TARIFS PRODUIT ETABLISSEMENT ////////////
  
  /** Récupère l'ensemble des tarifs de produits */
  async getAllProductPrices() {
    return this.api.getAllProductPrices()
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }


  /** Récupération de l'ensemble des tarifs de produist pour un établissement */
  async getAllProductPricesEstablishment(establishmentId) {
    return this.api.getAllProductPricesEstablishment(establishmentId)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** Récupération d'un tarif produit pour un établissement */
  async getProductPricesEstablishment(establishmentId, productPriceId) {
    return  this.api.getProductPricesEstablishment(establishmentId, productPriceId)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(establishmentId);
      converter.convert(error);
    });
  }

  /** Création d'un tarif de produit pour un établissement */
  async createProductPriceEstablishment(establishmentId, datas) {
    return this.api.createProductPriceEstablishment(establishmentId, datas)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409AlreadyExists(establishmentId);
      converter.convert(error);
    });
  }

  /** Mise à jour d'un tarif de produit pour un établissement */
  async updateProductPriceEstablishment(establishmentId, productPriceId, datas) {
    return this.api.updateProductPriceEstablishment(establishmentId, productPriceId, datas)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(establishmentId);
      converter.convert(error);
    });
  }

  /** Suppression du tarif d'un produit pour un établissement */
  async deleteProductPriceEstablishment(establishmentId, productPriceId) {
    return this.api.deleteProductPriceEstablishment(establishmentId, productPriceId)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(establishmentId);
      converter.add409Integrity(establishmentId);
      converter.convert(error);
    });
  }

  /**retourne la liste des activités pour un établissement */
  async getAllEstablishmentActivityDetails() {
    return this.api.getAllEstablishmentActivityDetails().catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }
}












/**
 * récupérer la liste des noms digitaux des établissements
 */
export async function getDigitalNames(apis) {
  logger.debugFull(
    _FILE_PATH,
    _M_GET_DIGITAL_NAME,
    "Récupération du nom digital de chaque établissements."
  );

  let api = apis.getEtablishmentApi();
  return (
    api
      .getDigitalNames()
      // .then((data) => data._embedded.establishmentDigitalNames)
      .then((data) => {
        data.sort(function(a, b) {
          return a.digitalName.localeCompare(b.digitalName);
        });

        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      })
  );
}

/**Charger un établisement en fonction de son nom digital  */
export async function getEtablishmentByDigitalName(apis, digitalName) {
  logger.debugFull(
    _FILE_PATH,
    _M_GET_ETABLIS_BY_DIGITAL_NAME,
    "Récupération d'un établisssment par son nom digital."
  );

  let api = apis.getEtablishmentApi();
  return api.getEtablishmentByDigitalName(digitalName).catch((error) => {
    let converter = new statusMapper.StatusConverter();
    converter.add404(digitalName);
    converter.convert(error);
  });
}

/**Charger un établisement en fonction de son id  */
/** @type {EtablishmentApi} */
export async function getEtablishmentById(apis, etablishmentId) {
  logger.debugFull(
    _FILE_PATH,
    _M_GET_ETABLIS_BY_ID,
    "Récupération d'un établisssment par son id."
  );

  let api = apis.getEtablishmentApi();
  return api.getEtablishmentById(etablishmentId).catch((error) => {
    let converter = new statusMapper.StatusConverter();
    converter.add404(etablishmentId);
    converter.convert(error);
  });
}

/**charger les données RAW par identifiant */
export async function getRawById(apis, etablishmentId) {
  logger.debugFull(
    _FILE_PATH,
    _M_GET_RAW_BY_ID,
    "Récupération du raw par id de l'établissement."
  );
  let api = apis.getEtablishmentApi();
  return api
    .getRawById(etablishmentId)
    .then((data) => data.raw)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(etablishmentId);
      converter.convert(error);
    });
}

/** Création d'un établissement */
export async function create(apis, establishment) {
  logger.debugFull(_FILE_PATH, _M_CREATE, "Création d'un établisssment.");
  let api = apis.getEtablishmentApi();
  return api.create(establishment);
}

//** Met à jour les informations d'un établissement */
export async function update(apis, etablishment) {
  logger.debugFull(
    _FILE_PATH,
    _M_UPDATE,
    "Mise à jour des infos structurées d'un établissement."
  );
  let api = apis.getEtablishmentApi();
  return api.update(etablishment).catch((error) => {
    let converter = new statusMapper.StatusConverter();
    converter.add404(etablishment.id);
    converter.convert(error);
  });
}

/** Met à jour les infos du raw d'un établissement */
export async function updateRaw(apis, etablishmentId, raw) {
  logger.debugFull(
    _FILE_PATH,
    _M_UPDATE_RAW,
    "Mise à jour les infos du raw d'un établissement."
  );
  let api = apis.getEtablishmentApi();
  return api.updateRaw(etablishmentId, raw).catch((error) => {
    let converter = new statusMapper.StatusConverter();
    converter.add404(etablishmentId);
    converter.convert(error);
  });
}

/**retourne la liste des activités pour un établissement */
export async function getActivities(apis, etablishmentId) {
  let api = apis.getEtablishmentApi();

  return api.getActivities(etablishmentId).catch((error) => {
    let converter = new statusMapper.StatusConverter();
    converter.convert(error);
  });
}

/**Ajouter une activité à l'établissement */
export async function addActivity(apis, etablishmentId, activityId) {
  let api = apis.getEtablishmentApi();

  return api.addActivity(etablishmentId, activityId).catch((error) => {
    let converter = new statusMapper.StatusConverter();
    converter.convert(error);
  });
}

/**Retirer une activité à l'établissement */
export async function removeActivity(apis, etablishmentId, activityId) {
  let api = apis.getEtablishmentApi();

  return api.removeActivity(etablishmentId, activityId).catch((error) => {
    let converter = new statusMapper.StatusConverter();
    converter.convert(error);
  });
}

/**
 * mettre à jour la liste des activités d'un établissement
 * @param {*} apis l'accès aux APIs
 * @param {*} etablishmentId l'indentifiant de l'établissement concerné
 * @param {*} initialIds la liste de activités initiales pour cet établissement
 * @param {*} valuesIds la liste de activités initiales pour cet établissement
 */
export async function updateActivitiesFrom(
  apis,
  etablishmentId,
  initialIds,
  valuesIds
) {
  let removed = initialIds.filter((a) => !valuesIds.find((b) => b == a.id));

  let added = valuesIds.filter((a) => !initialIds.find((b) => b.id == a));

  let api = apis.getEtablishmentApi();

  removed.forEach(async (activity) => {
    await api.removeActivity(etablishmentId, activity.id).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  });

  added.forEach(async (activityId) => {
    await api.addActivity(etablishmentId, activityId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  });
}
